import { Snapshot } from './snapshot';
import { Injectable } from '@angular/core';
import { Guide } from '../models/guide';
import { GuideSummary } from '../models/guide-summary';
import { Stitch, RemoteMongoClient, BSON, AnonymousCredential } from 'mongodb-stitch-browser-sdk';
import { BehaviorSubject } from 'rxjs';
import { SnapshotCriteria } from './snapshot-criteria';
import { ServerlessService } from './serverless.service';

@Injectable({
  providedIn: 'root'
})
export class GuidesDataService {
  static readonly textSearchIndexName = 'name_text_description_text';
  private collectionName = 'guides';
  private guides = new BehaviorSubject<Snapshot<GuideSummary>>(null);
  guides$ = this.guides.asObservable();

  private privateSnapshotCriteria = new SnapshotCriteria();
  set snapshotCriteria(value: SnapshotCriteria) {
    console.log('GuidesDataService::set-snapshotCriteria');
    this.privateSnapshotCriteria = value;
    this.loadSnapshot();
  }

  get snapshotCriteria(): SnapshotCriteria {
    return this.privateSnapshotCriteria;
  }

  constructor(private serverlessService: ServerlessService) {
    this.loadSnapshot();
  }

  async getById(id: string): Promise<Guide> {
    return new Promise(async (resolve, reject) => {
      try {
        const db = await this.serverlessService.getMongoDb();
        const item = await db
          .collection(this.collectionName)
          .findOne({ _id: new BSON.ObjectId(id) }) as Guide;
        if (item.dependencies) {
          item.dependenciesExpanded = await db
            .collection(this.collectionName)
            .aggregate([
              {
                $match: {
                  _id: { $in: item.dependencies.map(dependencyId => new BSON.ObjectId(dependencyId)) }
                }
              },
              {
                $project: {
                  _id: 1,
                  name: 1
                }
              },
              {
                $sort: {
                  name: 1
                }
              }
            ]).toArray() as Array<{ name: string, _id: string }>;
        }
        resolve(item);
      } catch (error) {
        reject(error);
      }
    });
  }

  async loadSnapshot() {
    console.log('GuidesDataService::loadSnapshot');

    try {
      // const skip = pageSize * pageIndex;
      const db = await this.serverlessService.getMongoDb();
      const collection = db.collection(this.collectionName);

      const operators = [];

      if (this.privateSnapshotCriteria && this.privateSnapshotCriteria.defaultFilter) {
        // Whole Word Search Technique...
        // operators.push({
        //     $match: { $text: { $search: filter } }
        // });

        // Regex Search Technique
        operators.push({
          $match: {
            $or: [
              {
                name: { $regex: `.*${this.privateSnapshotCriteria.defaultFilter}.*`, $options: 'i' }

              },
              {
                description: { $regex: `.*${this.privateSnapshotCriteria.defaultFilter}.*`, $options: 'i' }
              }
            ]
          }
        });
      }

      operators.push({
        $project: {
          _id: 1,
          name: 1,
          description: 1,
          runCount: 1,
          stepCount: { $size: '$steps' }
        }
      });

      // ~SMB: The skip and limit approach to paging is inefficient
      // and must  be revisited in the near future.  We may switch to
      // infinite scrolling which will be different anyway.
      const items = collection
        .aggregate(operators);
      // TODO: Wire these up in aggregate...
      // .sort({ name: 1 })
      // .skip(skip)
      // .limit(pageSize);

      const array = await items.toArray() as GuideSummary[];
      this.guides.next(new Snapshot(array.length, 0, 0, array as GuideSummary[]));
    } catch (error) {
      console.error(error);
    }
  }

  async insert(instance: Guide) {
    return new Promise(async (resolve, reject) => {
      try {
        const db = await this.serverlessService.getMongoDb();
        const addedItem = await db
          .collection(this.collectionName)
          .insertOne(instance);

        resolve(addedItem.insertedId);
      } catch (error) {
        reject(error);
      }
    });
  }
}
