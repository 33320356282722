<ng-container *ngIf="guide">
  <mat-toolbar class="toolbar mat-elevation-z6" color="secondary">
    <mat-toolbar-row>
      <button mat-mini-fab aria-label="Back" [routerLink]="['/']">
        <mat-icon class="backIcon">forward</mat-icon>
      </button>
      <span class="guideName">{{ guide?.name }}</span>
      <div class="dependencies mat-small">
        <!-- <mat-label >Dependencies: 1 of 4 incl.</mat-label> -->
        <mat-form-field>
          <mat-label>Dependencies</mat-label>
          <mat-select multiple *ngIf="guide.dependenciesExpanded; else noDependencies"
            (selectionChange)="onDependenciesChanged()" [(ngModel)]="includedDependencyIds">
            <mat-option *ngFor="let dependency of guide.dependenciesExpanded" [value]="dependency._id">
              {{dependency.name}}</mat-option>
          </mat-select>
          <ng-template #noDependencies>
            <mat-select [disabled]="true" value="null">
              <mat-option value="null">None</mat-option>
            </mat-select>
          </ng-template>
        </mat-form-field>
      </div>
      <span class="spacer"></span>
      <mat-button-toggle-group [(ngModel)]="viewMode">
        <mat-button-toggle value="read" aria-label="Read" matTooltip="Read">
          <mat-icon>menu_book</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="play" aria-label="Play" matTooltip="Play">
          <mat-icon>play_arrow</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle *ngIf="(serverlessService.authInfo$ | async).isLoggedIn" value="edit" aria-label="Edit" matTooltip="Edit">
          <mat-icon>edit</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="container">
    <app-step *ngFor="let step of getSteps(); let i = index" [step]="step" [index]="i" [viewMode]="viewMode"
      [previousStepCompleted]="i > 0 ? getSteps()[i - 1].isCompleted : true" (completed)="onStepCompleted($event)"
      (stepCountClicked)="onStepCountClicked(i)"></app-step>
  </div>
</ng-container>
