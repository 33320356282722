<div class="guideSummary">

  <app-block-field name="runs" [value]="guideSummary.runCount ? guideSummary.runCount : 0
  "></app-block-field>
  <app-block-field name="steps" [value]="guideSummary.stepCount"></app-block-field>
  <div>&nbsp;</div>
  <div class="guideDetails">
    <a [routerLink]="['guides', guideSummary._id.toString()]">{{ guideSummary.name }}</a>
    <div>{{ guideSummary.description }}</div>
  </div>
</div>
