<mat-toolbar color="primary" class="mat-elevation-z6" *ngIf="this.serverlessService.authInfo$ | async as authInfo">
  <div>
    <img class="logo" src="../assets/svg/tenzing-t-black.svg" />
    <span>{{ title }}</span>

    <div class="searchField">
      <mat-icon color="primary">search</mat-icon>
      <input type="search" (keyup)="onFilterChange($event.target.value)" (search)="onFilterChange($event.target.value)"
        matInput placeholder="Search...">
    </div>

    <span class="spacer"></span>

    <mat-icon class="actionIcon" *ngIf="authInfo.isLoggedIn && !authInfo.pictureUrl">person</mat-icon>
    <img class="avatar" *ngIf="authInfo.isLoggedIn && authInfo.pictureUrl" [src]="authInfo.pictureUrl">
    <a *ngIf="!authInfo.isLoggedIn" (click)="login()">Login</a>
    <a *ngIf="authInfo.isLoggedIn" (click)="logout()">Logout</a>
    <mat-icon class="actionIcon" matTooltip="Options">settings_applications</mat-icon>

  </div>
</mat-toolbar>
<div class="container">
  <router-outlet></router-outlet>
</div>
