import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Step } from '../../models/step';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ViewMode } from '../../models/view-mode';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {
  @Input() step: Step;
  @Input() index: number;
  @Input() previousStepCompleted: boolean;
  @Input() viewMode: ViewMode;
  @Output() completed = new EventEmitter<{ step: Step, index: number }>();
  @Output() stepCountClicked = new EventEmitter<{ step: Step, index: number }>();
  get isPlaying(): boolean {
    return this.viewMode === 'play';
  }

  constructor(public element: ElementRef) { }

  ngOnInit() {
  }

  onStepCountClicked() {
    this.stepCountClicked.emit({ step: this.step, index: this.index });
  }

  onCompletionCheckboxChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.completed.emit({ step: this.step, index: this.index });
    }
  }
}
