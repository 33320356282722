<mat-card *ngIf="step" #rootComponent [class.isFaded]="isPlaying && (step.isCompleted || !previousStepCompleted)">
  <mat-card-header>
    <mat-card-title>
      <div class="stepCount mat-elevation-z4" [class.isActive]="!(step.isCompleted || !previousStepCompleted)"><button
          (click)="onStepCountClicked()">{{ index + 1 }}</button></div>
      <div class="stepName">{{ step.name }}
        <span class="stepOwnerGuideName" *ngIf="step.ownerGuideName">[From: {{ step.ownerGuideName }}]</span>
      </div>

      <div class="stepDescription">{{ step.description }}</div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-checkbox color="primary"
      [matTooltip]="previousStepCompleted ? (step.isCompleted ? 'Check to mark incomplete' : 'Check to mark complete') : ''"
      class="completionCheckbox" [(ngModel)]="step.isCompleted" [class.noPlay]="!isPlaying"
      [disabled]="!previousStepCompleted" (change)="onCompletionCheckboxChanged($event)">
    </mat-checkbox>
    <app-snippet [snippet]="step.userAction"></app-snippet>
  </mat-card-content>
</mat-card>
