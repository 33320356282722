import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GuidesComponent } from './components/guides/guides.component';
import { MaterialModule } from './material.module';
import { GuideComponent } from './components/guide/guide.component';
import { HttpClientModule } from '@angular/common/http';
import { GuideSummaryComponent } from './components/guide-summary/guide-summary.component';
import { BlockFieldComponent } from './components/block-field/block-field.component';
import { SnippetComponent } from './components/snippet/snippet.component';
import { StepComponent } from './components/step/step.component';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Stitch } from 'mongodb-stitch-browser-sdk';

@NgModule({
  declarations: [
    AppComponent,
    GuidesComponent,
    GuideComponent,
    GuideSummaryComponent,
    StepComponent,
    BlockFieldComponent,
    SnippetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    console.log('AppModule::ctor -> Stitch.initializeDefaultAppClient');
    const client = Stitch.initializeDefaultAppClient(environment.stitchAppId);

    if (client.auth.hasRedirectResult()) {
      client.auth.handleRedirectResult().then(user => {
        console.log(`Logged in as ${user.id}`, user);
      });
    }
  }
}
