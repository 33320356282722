import { Injectable } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { Stitch, RemoteMongoClient, AnonymousCredential, StitchAppClient, GoogleRedirectCredential, RemoteMongoDatabase, StitchAuthListener, StitchUser, StitchAuth } from 'mongodb-stitch-browser-sdk';
import { BehaviorSubject } from 'rxjs';
import { AuthInfo } from './auth-info';

@Injectable({
  providedIn: 'root'
})
export class ServerlessService implements StitchAuthListener {
  private dbName = 'tenzing';
  private authInfo = new BehaviorSubject<AuthInfo>(new AuthInfo());
  authInfo$ = this.authInfo.asObservable();

  constructor() {
    console.log('ServerlessService::ctor');

    const client = Stitch.defaultAppClient;
    client.auth.addAuthListener(this);
  }

  login() {
    const client = Stitch.defaultAppClient;
    if (!client.auth.isLoggedIn || (client.auth as any).authInfo.loggedInProviderType === 'anon-user') {
      const credential = new GoogleRedirectCredential(location.origin);
      client.auth.loginWithRedirect(credential);
    } else {
      if (client.auth.user) {
        this.authInfo.next({
          isLoggedIn: true,
          displayName: client.auth.user.profile.firstName,
          pictureUrl: client.auth.user.profile.pictureUrl
        });
      }
    }
  }

  logout(): Promise<void> {
    const client = Stitch.defaultAppClient;
    return client.auth.logout();
  }

  async getMongoDb(): Promise<RemoteMongoDatabase> {
    const client = await this.getClient();
    const mongodb = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas');
    return mongodb.db(this.dbName);
  }

  getClient(): Promise<StitchAppClient> {
    return new Promise(async (resolve, reject) => {
      const client = Stitch.defaultAppClient;

      if (!client.auth.isLoggedIn) {
        console.log('ServerlessService::getClient -> client.auth.loginWithCredential');
        client.auth.loginWithCredential(new AnonymousCredential())
          .then((user) => {
            console.log('ServerlessService::getClient (Logged In as Anonymous)');
            resolve(client);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        console.log(`ServerlessService::getClient (Logged In as ${client.auth.user.loggedInProviderName})`);
        resolve(client);
      }
    });
  }

  onAuthEvent?(auth: StitchAuth) { }
  onUserAdded?(auth: StitchAuth, addedUser: StitchUser) { }
  onUserLinked?(auth: StitchAuth, linkedUser: StitchUser) { }
  onUserLoggedIn?(auth: StitchAuth, loggedInUser: StitchUser) {
    console.log(`ServerlessService::onUserLoggedIn`);
    this.authInfo.next({
      isLoggedIn: true,
      displayName: loggedInUser.profile.firstName,
      pictureUrl: loggedInUser.profile.pictureUrl
    });
  }
  onUserLoggedOut?(auth: StitchAuth, loggedOutUser: StitchUser) {
    console.log(`ServerlessService::onUserLoggedOut`);
    this.authInfo.next({
      isLoggedIn: false
    });
  }
  onActiveUserChanged?(auth: StitchAuth, currentActiveUser: StitchUser | undefined, previousActiveUser: StitchUser | undefined) { }
  onUserRemoved?(auth: StitchAuth, removedUser: StitchUser) { }
  onListenerRegistered?(auth: StitchAuth) { }
}
