import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-snippet',
  templateUrl: './snippet.component.html',
  styleUrls: ['./snippet.component.scss']
})
export class SnippetComponent implements OnInit {
  @Input() snippet: string;

  constructor() { }

  ngOnInit() {
  }

  onCopy() {
    const textArea = document.createElement('textarea');
    textArea.textContent = this.snippet;
    document.body.append(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
  }
}
