import { Component, OnInit } from '@angular/core';
import { GuidesDataService } from './services/guides-data.service';
import { Stitch, GoogleRedirectCredential } from 'mongodb-stitch-browser-sdk';
import { ServerlessService } from './services/serverless.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tenzing Way';

  constructor(public guidesApiService: GuidesDataService, public serverlessService: ServerlessService) {
  }

  onFilterChange(value: string) {
    this.guidesApiService.snapshotCriteria = {
      ...this.guidesApiService.snapshotCriteria,
      pageIndex: 0,
      defaultFilter: value
    };
  }

  async logout() {
    await this.serverlessService.logout();
  }

  async login() {
    await this.serverlessService.login();
  }
}
