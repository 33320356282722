import { AfterViewChecked, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Guide } from 'src/app/models/guide';
import { Step } from 'src/app/models/step';
import { ViewMode } from '../../models/view-mode';
import { StepComponent } from '../step/step.component';
import { GuidesDataService } from 'src/app/services/guides-data.service';
import { ServerlessService } from 'src/app/services/serverless.service';

declare var PR;

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit, AfterViewChecked {
  private privateViewMode: ViewMode = 'read';

  @ViewChildren(StepComponent) stepComponents: QueryList<StepComponent>;

  guide: Guide;
  includedDependencyIds: string[];
  includedGuides: Guide[] = [];

  get viewMode() { return this.privateViewMode; }
  set viewMode(value: ViewMode) {
    this.getSteps().forEach(step => step.isCompleted = false);
    this.onStepCountClicked(0);
    this.privateViewMode = value;
  }

  constructor(private route: ActivatedRoute, public guidesService: GuidesDataService, public serverlessService: ServerlessService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      const id = params.get('id');
      this.guide = await this.guidesService.getById(id);
      setTimeout(() => this.onStepCountClicked(0));
    });
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      PR.prettyPrint();
    });
  }

  onDependenciesChanged() {
    this.includedGuides.length = 0;
    this.includedDependencyIds.forEach(async (id, index) => {
      this.includedGuides[index] = await this.guidesService.getById(id);
    });
    this.onStepCountClicked(0);
  }

  onStepCountClicked(stepIndex: number) {
    const stepComponent = this.stepComponents.find((_, index) => index === stepIndex);
    this.bringStepComponentIntoView(stepComponent);
  }

  onStepCompleted(details: { step: Step, index: number }) {
    const stepComponent = this.stepComponents.find((component, index) => {
      return (+index >= (+details.index + 1)) && !component.step.isCompleted;
    });
    this.bringStepComponentIntoView(stepComponent);
  }

  getSteps() {
    const steps: Step[] = [];
    for (const includedGuide of this.includedGuides) {
      includedGuide.steps.forEach(step => step.ownerGuideName = includedGuide.name);
      steps.push(...includedGuide.steps);
    }
    steps.push(...this.guide.steps);
    return steps;
  }

  private bringStepComponentIntoView(stepComponent: StepComponent) {
    if (stepComponent && stepComponent.element) {
      window.scroll({
        top: (stepComponent.element.nativeElement.offsetTop - 125),
        behavior: 'smooth'
      });
    }
  }
}
