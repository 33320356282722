import { Component, OnInit, Input } from '@angular/core';
import { GuideSummary } from 'src/app/models/guide-summary';

@Component({
  selector: 'app-guide-summary',
  templateUrl: './guide-summary.component.html',
  styleUrls: ['./guide-summary.component.scss']
})
export class GuideSummaryComponent implements OnInit {
  private privateGuideSummary: GuideSummary;
  @Input() set guideSummary(value: GuideSummary) {
    this.privateGuideSummary = value;
  }
  get guideSummary(): GuideSummary {
    return this.privateGuideSummary;
  }

  constructor() { }

  ngOnInit() {
  }

}
